<template>
  <section>
    <rooms-list :container="gridContainer" />
  </section>
</template>

<script>
import RoomsList from '@/views/apps/rooms/components/RoomsAll.vue';
import ListContainer from '@core/constants/ListContainer';

export default {
  components: {
    RoomsList,
  },
  computed: {
    gridContainer() {
      return ListContainer.Grid;
    },
  },
};
</script>
